import { Component, HostListener, OnInit } from '@angular/core';
import { HeaderComponent } from 'src/app/templates/headers/top-header-switcher.component';
import { DefaultBreadcrumbComponent } from '../components/default-breadcrumb/default-breadcrumb.component';
import { FCRouterLinkDirective } from '../../../directives/fc-router-link.directive';
import { NgFor, NgIf, NgClass, NgStyle } from '@angular/common';
import { IselectDesktopMenuComponent } from 'src/app/templates/headers/iselect-top-header/iselect-desktop-menu/iselect-desktop-menu.component';
import { IselectMobileMenuComponent } from 'src/app/templates/headers/iselect-top-header/iselect-mobile-menu/iselect-mobile-menu.component';
import { IselectHeaderIconsComponent } from 'src/app/templates/headers/iselect-top-header/iselect-header-icons/iselect-header-icons.component';
import { Router } from '@angular/router';

export interface ISelectChild {
  title: string;
  icon: string;
  href?: string;
  route?: string;
}

export interface ISelectMenu {
  title: string;
  children: ISelectChild[];
  href?: string;
}

@Component({
    selector: 'fc-iselect-top-header',
    templateUrl: './iselect-top-header.component.html',
    styleUrls: ['./iselect-top-header.component.scss'],
    standalone: true,
    imports: [
        NgFor,
        NgIf,
        NgClass,
        NgStyle,
        FCRouterLinkDirective,
        IselectDesktopMenuComponent,
        IselectMobileMenuComponent,
        IselectHeaderIconsComponent,
        DefaultBreadcrumbComponent,
    ],
})
export class IselectTopHeaderComponent implements HeaderComponent, OnInit {
  menu: ISelectMenu[] = [];
  showMobile = false;
  showOverlay: boolean = false;

  constructor() { }

  ngOnInit(): void {
    const baseRoute = '/compare/';
    const baseUrl = 'https://www.iselect.com.au';
    this.menu = [
      {
        title: 'Insurance',
        children: [
          { title: 'Health Insurance', href: `${baseUrl}/health-insurance/`,  icon: `#health`},
          { title: 'Car Insurance', href: `${baseUrl}/car-insurance/`,  icon: `#car`  },
          { title: 'Home & Contents', href: `${baseUrl}/home-and-contents/`,  icon: `#homecontents`  },
          { title: 'Life Insurance', href: `${baseUrl}/life/`,  icon: `#life`  },
          { title: 'Income Protection', href: `${baseUrl}/life/income-protection-insurance/`,  icon: `#incomeprotection`  },
          { title: 'Business Insurance', href: `${baseUrl}/business-insurance/`,  icon: `#businessinsurance`  },
          { title: 'Travel Insurance', href: `${baseUrl}/travel-insurance/`,  icon: `#travelinsurance`  },
          { title: 'Pet Insurance', href: `${baseUrl}/pet-insurance/`,  icon: `#petinsurance`  },
          { title: 'Overseas Visitor Cover ', href: `${baseUrl}/overseas-visitors-health-cover/`,  icon: `#ovc`  }
        ]
      },
      {
        title: 'Utilities',
        children: [
          { title: 'Electricity &amp; Gas', href: `${baseUrl}/energy/`,  icon: `#energy`  },
          { title: 'Internet', href: `${baseUrl}/internet/`,  icon: `#internet`  },
          //{ title: 'Mobile Plans', href: `${baseUrl}/mobile/`,  icon: `#mobileplans`  }
        ]
      },
      {
        title: 'Credit Cards & Loans',
        children: [
          { title: 'Credit Cards', route: `${baseRoute}credit-cards`,  icon: `#creditcards`  },
          { title: 'Home Loans', href: `${baseUrl}/home-loans/`,  icon: `#homeloans`  },
          { title: 'Car Loans', route: `${baseRoute}car-loans`,  icon: `#carloans`  },
          { title: 'Personal Loans', route: `${baseRoute}personal-loans`,  icon: `#personalloans`  },
          //{ title: 'Business Loans', href: `${baseUrl}/business-loans/`,  icon: `#business_loans`  }


        ]
      }, {
        title: 'Moving House?', href: `${baseUrl}/moving-house/`, children: []
      }
    ];
  }
}
