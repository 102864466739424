import { NgClass, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IselectIconLinkComponent } from 'src/app/templates/headers/iselect-top-header/iselect-icon-link/iselect-icon-link.component';
import { ISelectMenu } from 'src/app/templates/headers/iselect-top-header/iselect-top-header.component';

@Component({
  selector: 'fc-iselect-desktop-menu',
  standalone: true,
  templateUrl: './iselect-desktop-menu.component.html',
  styleUrls: ['./iselect-desktop-menu.component.scss'],
  imports: [
    NgClass,
    NgForOf,
    NgIf,
    IselectIconLinkComponent,
    NgTemplateOutlet
  ]
})
export class IselectDesktopMenuComponent {
  @Input({required: true}) menu: ISelectMenu[];
  @Output() showOverlay = new EventEmitter<boolean>();
 // enabled: boolean = true;
  menuActive: boolean = false;
  private hasSubmenu: boolean = false;

  onMenuActive(isActive: boolean): void {
    this.menuActive = isActive;

    this.toggleOverlay();
  }

  onMouseOver(menu: ISelectMenu): void {
    this.hasSubmenu = (menu.children?.length ?? 0 > 0) ? true : false;
    this.toggleOverlay();
  }

  toggleOverlay() {
    this.showOverlay.emit(this.menuActive && this.hasSubmenu)
  }

  onClose() {
    console.log('On Close Fired!!!');
    this.onMenuActive(false);
    this.hasSubmenu = false;
  }
}
