<ng-container  [ngTemplateOutlet]="link.href ? standard : router"></ng-container>
<ng-template #standard>
    <a [href]="link.href" class="ist-submenu-item">
        <svg class="ist-icons" [ngClass]="{'menuActive': isActive(link) }">
            <use class="icon-default" [attr.xlink:href]="link.icon"></use>
            <use class="icon-hover"  [attr.xlink:href]="link.icon + '_hover'"></use>
            <use class="icon-active" [attr.xlink:href]="link.icon + '_active'"></use>
        </svg>
        <span [innerHTML]="link.title"></span>
    </a>
</ng-template>
<ng-template #router>
    <a [FCRouterLink]="link.route" class="ist-submenu-item" (click)="onClose.emit()">
        <svg class="ist-icons" [ngClass]="{'menuActive': isActive(link) }">
            <use class="icon-default" [attr.xlink:href]="link.icon"></use>
            <use class="icon-hover" [attr.xlink:href]="link.icon + '_hover'"></use>
            <use class="icon-active" [attr.xlink:href]="link.icon + '_active'"></use>
        </svg>
        <span [innerHTML]="link.title"></span>
    </a>
</ng-template>



