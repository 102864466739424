import { Component, Input } from '@angular/core';
import { SectorStaticFooterDto } from 'src/app/models/generated/content/SectorStaticPageDto';
import { SeoService } from 'src/app/services/seo.service';
import { FooterComponent } from 'src/app/templates/footers/footer-switcher.component';
import { FCRouterLinkDirective } from '../../../directives/fc-router-link.directive';
import { NgForOf } from '@angular/common';
import { AccordianBaseComponent } from 'src/app/shared/accordian-base-compontent';

interface SocialInks {
  alt: string;
  icon: string,
  href: string;
}
@Component({
    selector: 'fc-iselect-footer',
    templateUrl: './iselect-footer.component.html',
    styleUrls: ['iselect-footer.component.scss'],
    standalone: true,
    imports: [FCRouterLinkDirective, NgForOf]
})
export class IselectFooterComponent implements FooterComponent {
  @Input() links: SectorStaticFooterDto[];

  private socialUrl: string = 'https://ik.imagekit.io/fairaus/iselect/icons/social';
  private accordian: AccordianBaseComponent;

  constructor(public seoService: SeoService) {
    this.accordian = new AccordianBaseComponent('ist-content', 'ist-expanded');
  }
 
  onClick(event: any): void {
    this.accordian.toggleAccordian(event);
  }

  socialLinks: SocialInks[] =
    [
      { alt: 'Facebook Logo', icon: `${this.socialUrl}/facebook.svg`, href: 'http://www.facebook.com/iselect' },
      { alt: 'X/Twitter Logo',  icon: `${this.socialUrl}/twitter.svg`, href: 'https://twitter.com/iselect' },
      { alt: 'LinkedIn Logo',  icon: `${this.socialUrl}/linkedin.svg`, href: 'https://www.linkedin.com/company/iselect' },
      { alt: 'Instagram Logo', icon: `${this.socialUrl}/instagram.svg`, href: 'https://www.instagram.com/iselect' },
      { alt: 'YouTube Logo',  icon: `${this.socialUrl}/youtube.svg`, href: 'https://www.youtube.com/MriSelect' }
    ];
}
