import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'fc-iselect-header-icons',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './iselect-header-icons.component.html'
})
export class IselectHeaderIconsComponent {

}
