import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass, NgForOf, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import { ISelectMenu } from 'src/app/templates/headers/iselect-top-header/iselect-top-header.component';
import { IselectIconLinkComponent } from 'src/app/templates/headers/iselect-top-header/iselect-icon-link/iselect-icon-link.component';

@Component({
  selector: 'fc-iselect-mobile-menu',
  standalone: true,
  imports: [NgStyle, NgForOf, NgIf, NgClass, NgTemplateOutlet, IselectIconLinkComponent],
  templateUrl: './iselect-mobile-menu.component.html',
  styleUrls: ['./iselect-mobile-menu.component.scss']
})
export class IselectMobileMenuComponent {
  private _showMenu: boolean;

  menuStyle: { left: string } = {
    left: '-100%',
  };

  subMenuStyle: { left: string } = {
    left: '-100%',
  };

  subMenu: ISelectMenu | null = null;


  @Input({required: true}) menu: ISelectMenu[];
  @Output() showMobile = new EventEmitter<boolean>();
  @Input({required: true}) set showMenu(open: boolean) {
    this._showMenu = open;
    this.toggleMobileMenu(open);
  }
  get showMenu(): boolean {
    return this._showMenu;
  }

  toggleMobileMenu(open: boolean): void {
    if (!open) {
      this.showMobile.emit(false);
      this.showSubMenu(false, null);
    }
    this.menuStyle.left = (open) ? '0px' : '-100%';
  }

  showSubMenu(open: boolean, menu: ISelectMenu  | null): void {

    if (menu) {
      this.subMenu =  menu;
    }
    
    if (open && (menu?.children?.length ?? 0) > 0){
      this.subMenuStyle.left = '0px';
    } else {
      this.subMenuStyle.left = '-100%';
    }
  }


  
}
