<fc-iselect-header-icons style="display: none;"></fc-iselect-header-icons>
<div class="ist-menu-row" [ngClass]="{'submenuOpen': showMobile || showOverlay}">
    <div class="ist-top-menu">
        <div class="ist-mobile-btn" (click)="showMobile = !showMobile">
            <i *ngIf="!showMobile" class="ist-icon ist-burger"></i>
            <i *ngIf="showMobile"class="ist-close fas fa-times"></i>
        </div>
        <div class="ist-logo">
            <a href="https://www.iselect.com.au">
                <img width="100px" height="44px" src="https://ik.imagekit.io/fairaus/iselect/iSelect_full_Newlogo_24.svg"
                    loading="eager" alt="Compare insurance companies in Australia with iSelect">
            </a>
        </div>
        <div class="ist-providedBy">
            <label>Comparison Powered by</label>
            <a href="https://www.faircomparison.com" rel="nofollow"><img width="120" height="31" loading="eager"
                    src="https://ik.imagekit.io/fairaus/faircomparison/fc_logo_black.svg"
                    alt="Fair Comparison - white label comparison technology provider"></a>
        </div>
        <div class="ist-menu-container" >
            <fc-iselect-desktop-menu [menu]="menu" (showOverlay)="showOverlay = $event"></fc-iselect-desktop-menu>
        </div>
    </div>
</div>
<div class="ist-breadcrumb-row">
    <fc-default-breadcrumb [showOnRoot]="true" [showExternal]="true" [showPreviousOnly]="false" [showLowest]="true"></fc-default-breadcrumb>
</div>
<div class="ist-overlay" [ngClass]="{'show': showOverlay}" ></div>
<fc-iselect-mobile-menu [menu]="menu" [showMenu]="showMobile" (showMobile)="showMobile = $event"></fc-iselect-mobile-menu>