<div class="ist-mobile-menu" [ngStyle]="menuStyle">
    <div class="ist-mobile-content">
    <ul class="ist-mobile-menu-ul">
        <li class="ist-top-link-li" *ngFor="let m of menu" [ngClass]="{'showArrow': m.children.length > 0}">
            <a *ngIf="m.href" class="ist-top-link" [href]="m.href" [innerHtml]="m.title"></a>
            <span *ngIf="!m.href" class="ist-top-link" [innerHtml]="m.title" (click)="showSubMenu(true, m)"></span>
        </li>
    </ul>
    </div>
</div>


<div class="ist-mobile-submenu" [ngStyle]="subMenuStyle">
    <div class="ist-mobile-content">
        <div class="ist-mobile-overflow" >
            <ul class="ist-mobile-submenu-ul">
                <li class="ist-back" (click)="showSubMenu(false,null)" [innerText]="subMenu?.title"></li>
                <li class="ist-link" *ngFor="let m of subMenu?.children">
                    <fc-iselect-icon-link [link]="m" (onClose)="toggleMobileMenu(false)"></fc-iselect-icon-link>
                </li>   
            </ul>
        </div>
    </div>
</div>
