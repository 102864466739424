
<div class="ist-footer-content">
    <div class="ist-logo">
        <a class="ist-logo" href="https://www.iselect.com.au">
            <img width="77px" height="34px" src="https://ik.imagekit.io/fairaus/iselect/iSelect_full_Newlogo_24.svg"
                loading="eager" alt="Compare insurance companies in Australia with iSelect">
        </a>
    </div>
    <div class="ist-about">
        <span class="ist-link-header" (click)="onClick($event)">About iSelect</span>
        <div class="ist-content">
            <ul class="ist-links">
                <li><a href="https://www.iselect.com.au/how-iselect-works/" target="_blank" >How iSelect Works</a></li>
                <li><a href="https://www.iselect.com.au/corporate/our-company/" target="_blank" >Our Company </a></li>
                <li><a href="https://www.iselect.com.au/corporate/careers/" target="_blank" >Careers</a></li>
                <li><a href="https://www.iselect.com.au/corporate/media-centre/" target="_blank" >Media Centre</a></li>
                <li><a href="https://www.iselect.com.au/new-partner-enquiry-form/" target="_blank" >Become our Partner</a></li>
                <li><a href="https://www.iselect.com.au/contact-us/" target="_blank" >Contact us</a></li>
            </ul>
        </div>
    </div>

    <div class="ist-important">
        <span class="ist-link-header" (click)="onClick($event)">Important Information</span>
        <div class="ist-content">
            <ul class="ist-links">
                <li><a [FCRouterLink]="seoService.getDisclaimerPage('terms-and-conditions')" id="fc-footer-website-terms">Fair Comparison Website Terms & Conditions</a></li>
                <li><a [FCRouterLink]="seoService.getDisclaimerPage('services-guide')" id="fc-footer-services"> Fair Comparison Services Guide</a></li>
                <li><a href="https://www.iselect.com.au/content/uploads/2023/11/iSelect-Services-Guide-.pdf" target="_blank">iSelect Services Guide</a></li>
                <li><a [FCRouterLink]="seoService.getDisclaimerPage('privacy-policy')" id="fc-footer-privacy-policy">Fair Comparison Privacy Policy</a></li>
            </ul>
        </div>
    </div>
    <div class="ist-social">
        <ul>
            <li *ngFor="let s of socialLinks"><a [href]="s.href"><img width="40px" height="40px" [src]="s.icon" [alt]="s.alt"></a></li>
        </ul>
    </div>
</div>

<div class="ist-footer-disclaimer">
    <div class="ist-disclaimer">Tyrian Pty Ltd - ACN: 158 954 655</div>
    <div class="ist-poweredBy">
        <div class="ist-fc-text">Comparison <br />Powered by</div>
        <a class="ist-fc-icon" href="https://www.faircomparison.com" rel="nofollow"><img width="120" height="31" loading="lazy"
            src="https://ik.imagekit.io/fairaus/faircomparison/fc_logo_black.svg"
            alt="Fair Comparison - white label comparison technology provider"></a>
    </div>
</div>