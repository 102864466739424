import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { RenderHelper } from 'src/app/models/shared/helpers/RenderHelper';
import { IScriptInitilizer } from 'src/app/services/script.service';
import { EnvironmentOptionsType } from 'src/environments/environmentDefinitions';

@Injectable({ providedIn: 'root' })
export class iSelectScriptInitilizer implements IScriptInitilizer {
    helper: RenderHelper;
    constructor(@Inject(DOCUMENT) private doc: Document) {
        this.helper = new RenderHelper(doc);
    }

    renderPreLoad(): void {
        this.helper.renderPreload('iselect-proxima-font','https://cdn.comparisonpartner.com/fonts/iselect/proximaNova/Proxima_Regular.ttf', '');
        this.helper.renderPreload('iselect-museo-font','https://cdn.comparisonpartner.com/fonts/iselect/museo/museo-700-webfont.woff2', '');
        this.helper.renderPreload('iselect-toroka-font',' https://cdn.comparisonpartner.com/fonts/iselect/toroka/TorokaMedium/font.woff2', '');

       
        
    }
    renderStyleSheet(): void { }
    renderProrityStyle(): void {}
    renderPreFetch(): void { }
    renderScripts(): void { }
    renderTemplates(e: EnvironmentOptionsType): void { }

}

