<ul class="ist-menu" (mouseenter)="onMenuActive(true)" (mouseleave)="onMenuActive(false)">
    <li *ngFor="let m of menu" [ngClass]="{'showSubmenu': m.children.length > 0 && menuActive} " (mouseover)="onMouseOver(m)">
        <a *ngIf="m.href" class="ist-anchor-a" [href]="m.href" [innerHtml]="m.title"></a>
        <span *ngIf="!m.href" class="ist-anchor-span" [innerHtml]="m.title"></span>
        <div class="ist-submenu-container">
            <div class="ist-submenu">
                <fc-iselect-icon-link *ngFor="let c of m.children; let i = index" [link]="c" ngClass="ist-desktop-link" (onClose)="onClose()" ></fc-iselect-icon-link>
            </div>
        </div>
    </li>
</ul>