import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ISelectChild } from 'src/app/templates/headers/iselect-top-header/iselect-top-header.component';
import { FCRouterLinkDirective } from 'src/app/directives/fc-router-link.directive';
import { Router } from '@angular/router';

@Component({
  selector: 'fc-iselect-icon-link',
  standalone: true,
  imports: [CommonModule, FCRouterLinkDirective],
  templateUrl: './iselect-icon-link.component.html',
  styleUrls: ['./iselect-icon-link.component.scss']
})
export class IselectIconLinkComponent {
  @Input({ required: true}) link: ISelectChild 
  @Output() onClose = new EventEmitter();

  constructor(private router: Router) { }

  isActive(link: ISelectChild) {
    if (link.route) {
      return link.route.indexOf(this.router.url) > 0 ;
    }
    return false;
  }
}
